import { onUnmounted, watch } from "vue";

import { storeToRefs } from "pinia";

import { useDsApiStore, useDsMaintenance } from "@devsalsa/vue-core";

import { ErrorHandler } from "@/core/shared/helpers/Error/ErrorHandler";
import StatusService from "@/core/shared/services/StatusService";

const useApiConnectionChecker = (intervalMs = 30 * 1000): void => {
  const { maintenance } = storeToRefs(useDsApiStore());
  let pingIntervalId: ReturnType<typeof setInterval> | null = null;

  const { setOff } = useDsMaintenance();

  const ping = async (): Promise<void> => {
    try {
      await StatusService.get();
      setOff();
    } catch (error) {
      ErrorHandler.handle(error);
    }
  };

  const stopPinging = () => {
    if (pingIntervalId) {
      clearInterval(pingIntervalId);
      pingIntervalId = null;
    }
  };

  const startPinging = (interval = intervalMs) => {
    stopPinging();
    ping();
    pingIntervalId = setInterval(ping, interval);
  };

  watch(
    maintenance,
    (newValue) => {
      if (newValue) {
        startPinging();
      } else {
        stopPinging();
      }
    },
    { immediate: true }
  );

  onUnmounted(stopPinging);
};

export default useApiConnectionChecker;
